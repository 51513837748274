import React from "react"

import SEO from "../components/seo"
import DownloadsContent from "../components/downloadsContent"
import PageTitle from "../components/shared/PageTitle"

const DownloadsPage = () => {
  return (
    <div className="">
      <SEO
        title="Downloads"
        keywords={[
          `Sioux Lookout`,
          `hockey`,
          `downloads`,
          `files`,
          `documents`,
          `information`,
        ]}
      ></SEO>

      <PageTitle>Downloads</PageTitle>

      <div className="downloadsPage__table">
        <DownloadsContent />
      </div>
    </div>
  )
}

export default DownloadsPage
